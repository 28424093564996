// Import Main CSS file from theme
@import "main";

h2 {
  padding: 8px 4px 8px 12px;
  background-color: #f6f6f6;
  border-left: 6px solid hsl(204, 71%, 39%);
}

h3 {
  padding: 0 0 8px;
  border-bottom: solid 2px hsl(204, 71%, 39%);
}

body, button, .card {
  font-family: Noto Sans JP,BlinkMacSystemFont,Helvetica Neue,Yu Gothic,YuGothic,"\30D2\30E9\30AE\30CE\89D2\30B4 ProN W3",Hiragino Kaku Gothic ProN,Arial,"\30E1\30A4\30EA\30AA",Meiryo,sans-serif;
  color: #454e58;
  line-height: 1.4;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6, .title {
  color: #454e58;
}

.menu-list {
  font-size: 0.9rem;
}

.menu-list li ul {
  margin: 0em 0.5em 0.5em 1em;
}

.menu-list a {
  padding: 0.4em 0.75em;
}

.comment {
  max-width: 750px;
}

.commentLabel {
  margin-top: 32px;
}

.commentItem{
  margin-bottom: 12px;
}

.message.is-dark pre {
  background-color: #fafafa;
  margin: 0px;
  padding: 0px;
}