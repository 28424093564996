div.highlight {
    margin-bottom: 1rem;
}

@include desktop {
    .navbar-item {
        &.is-hoverable:hover {
            .navbar-dropdown {
                display: block;
            }
        }
    }
}

.contents {
    box-shadow: $box-shadow;
    padding: 1.5rem;
    margin-bottom: 3rem;
}

.hero-darken {
    background-color: rgba($hero-darken, 0.5);
}